@use './theme';

$theme-color-pink: #d24186;
$theme-color-pink-dark: darken($theme-color-pink, 10%);
$theme-color-pink-light: lighten($theme-color-pink, 30%);
$theme-color-pink-lighter: lighten($theme-color-pink, 40%);

// pink theme map for generating css custom properties
$theme-map-pink: (
  theme.$css-var-name-theme-color: $theme-color-pink,
  theme.$css-var-name-theme-color-dark: $theme-color-pink-dark,
  theme.$css-var-name-theme-color-light: $theme-color-pink-light,
  theme.$css-var-name-theme-color-lighter: $theme-color-pink-lighter,
);
