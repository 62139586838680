@use '~@timelog/ui-library/src/assets/styles/mixins/media-queries.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/typography.scss';
@use '~@timelog/ui-library/src/assets/styles/mixins/resets.scss';
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.TimeTrackerButton {
  @include resets.reset-button();
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: app-sizes.$height-header;
  height: app-sizes.$height-header;
  margin-right: spaces.$space-size-4;
  border-radius: 0;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: spaces.$space-size-3;
    right: -(spaces.$space-size-2);
    bottom: spaces.$space-size-3;
    transform: translateX(50%);
    width: 2px;
    background-color: colors.$color-border;
  }

  &:hover,
  &:focus-visible {
    background-color: colors.$color-gray-05;
  }

  &[aria-expanded='true'] {
    background-color: colors.$color-gray-05;
    color: colors.$color-gray-70;
  }

  &__icon {
    color: colors.$color-gray-60;
  }

  &____isActive {
    width: auto;
    padding-right: spaces.$space-size-3;
    padding-left: spaces.$space-size-3;

    .TimeTrackerButton__time {
      margin-left: spaces.$space-size-1;
      font-size: typography.$font-size-body;
    }

    .TimeTrackerButton__colon {
      animation: animation-blink 1s steps(2, start) infinite;
    }

    .TimeTrackerButton__heart {
      fill: colors.$theme-color-pink;
      transform-origin: center;
      animation: animation-pulse 2s infinite;
    }

    @media screen and (prefers-reduced-motion: reduce) {
      .TimeTrackerButton__colon {
        animation: none;
      }

      .TimeTrackerButton__heart {
        animation: none;
      }
    }
  }
}

@keyframes animation-blink {
  to {
    visibility: hidden;
  }
}

@keyframes animation-pulse {
  0% {
    transform: scale(0.9);
  }

  20% {
    transform: scale(1.05);
  }

  40% {
    transform: scale(0.9);
  }

  60% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.9);
  }
}
