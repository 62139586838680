@use '../variables/typography';

@mixin reset-button {
  appearance: none;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-family: typography.$font-family;
}

@mixin reset-list {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
