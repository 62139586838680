@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors';
@use '~@timelog/ui-library/src/assets/styles/variables/typography';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces';

.Wrapper {
  display: block;
}

.ParentLink {
  display: block;
  font-weight: typography.$font-weight-normal;
  position: relative;
  color: colors.$color-gray-80;
  padding: spaces.$space-size-3 spaces.$space-size-6 spaces.$space-size-3 spaces.$space-size-8;
  margin-bottom: 2px;

  // We disable the rule as we just want the browser native focus state
  /* stylelint-disable-next-line a11y/selector-pseudo-class-focus */
  &[aria-current='page'],
  &:hover {
    background-color: colors.$color-gray-05;
  }

  &[aria-selected='true'] {
    background-color: colors.$color-gray-05;
    box-shadow: 0 0 0 1px colors.$color-gray-40;
  }
}
