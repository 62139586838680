@use '../global/grays';

// attention color var names
$color-alert: #fd7f7f;
$color-alert-dark: #a71212;
$color-alert-light: #fff2f2;
$color-success: #62c9af;
$color-success-dark: #008261;
$color-success-light: #f2fffc;
$color-warning: #ffcb75;
$color-warning-dark: #bb7401;
$color-warning-light: #fff2dc;

// Colors for notices
$color-notice-00: $color-success;
$color-notice-25: mix(grays.$color-white, $color-notice-00, 33%);
$color-notice-50: $color-warning;
$color-notice-100: $color-alert;
$color-notice-75: mix(grays.$color-white, $color-notice-100, 33%);
