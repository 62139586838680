@use './theme';

$theme-color-turquoise: #6caeae;
$theme-color-turquoise-dark: #4a8686;
$theme-color-turquoise-light: #d6e9e9;
$theme-color-turquoise-lighter: #eaf5f5;

// turquoise theme map for generating css custom properties
$theme-map-turquoise: (
  theme.$css-var-name-theme-color: $theme-color-turquoise,
  theme.$css-var-name-theme-color-dark: $theme-color-turquoise-dark,
  theme.$css-var-name-theme-color-light: $theme-color-turquoise-light,
  theme.$css-var-name-theme-color-lighter: $theme-color-turquoise-lighter,
);
