@use '~@timelog/ui-library/src/assets/styles/variables/spaces';
@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors';

.widgetWrapper {
  padding-block: spaces.$space-size-4;
  padding-inline: spaces.$space-size-5;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
}

.tips {
  margin-top: auto;
  padding-top: spaces.$space-size-5;
}

.label {
  display: flex;
  gap: spaces.$space-size-1;
  align-items: center;
}

%trending-style {
  padding: spaces.$space-size-2 spaces.$space-size-4;
  display: flex;
  justify-content: center;
  margin-bottom: spaces.$space-size-2;
  margin-left: 0;
  gap: spaces.$space-size-2;
}

.trendingUp {
  @extend %trending-style;
  color: colors.$color-success-dark;
  background-color: colors.$color-success-light;
}

.trendingDown {
  @extend %trending-style;
  color: colors.$color-alert-dark;
  background-color: colors.$color-alert-light;
}

.defWrapper {
  display: flex;
  flex-direction: column-reverse;

  > *:not(:last-child) {
    margin-top: spaces.$space-size-1;
  }
}
