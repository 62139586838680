@use './theme';

$theme-color-green: #82c578;
$theme-color-green-dark: #33a325;
$theme-color-green-light: #e2f0b1;
$theme-color-green-lighter: #f0fad0;

// Green theme map for generating css custom properties
$theme-map-green: (
  theme.$css-var-name-theme-color: $theme-color-green,
  theme.$css-var-name-theme-color-dark: $theme-color-green-dark,
  theme.$css-var-name-theme-color-light: $theme-color-green-light,
  theme.$css-var-name-theme-color-lighter: $theme-color-green-lighter,
);
