@use '~@timelog/ui-library/src/assets/styles/variables/spaces';

.widgetWrapper {
  padding-block: spaces.$space-size-4;
  padding-inline: spaces.$space-size-5;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  max-height: 300px;
}
