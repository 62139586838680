@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors';
@use '~@timelog/ui-library/src/assets/styles/variables/shadows';
@use '~@timelog/ui-library/src/assets/styles/variables/border-radius';
@use '~@timelog/ui-library/src/assets/styles/mixins/resets';

$min-child-panel-width: 300px;

.button {
  @include resets.reset-button();
  width: 100%;
  padding: spaces.$space-size-2;
  text-align: start;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: colors.$color-gray-10;
  }

  &[aria-expanded='true'] {
    background-color: colors.$color-gray-05;
    box-shadow: 0 0 0 1px colors.$color-gray-40;
  }
}

.buttonActive {
  background-color: colors.$color-gray-10;
}

.inputField {
  position: absolute;
  top: calc(100% + spaces.$space-size-2);
  left: 0;
  right: 0;
  border-radius: border-radius.$border-radius-small;
  border: 1px solid colors.$color-border;
  box-shadow: shadows.$box-shadow-default;
  background-color: colors.$color-white;
  z-index: 1;
  padding: spaces.$space-size-2;
}

.inputWrapper {
  position: relative;
}
