@use '~@timelog/ui-library/src/assets/styles/mixins/media-queries';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces';
@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors';

$max-main-contain-width: 1024px;
$min-main-contain-width: 608px;
$max-sidebar-width: 380px;
$max-mobile-sidebar-width: 48px;

.dashboard {
  padding-inline: spaces.$space-size-12;
  display: grid;
  gap: spaces.$space-size-4;
  grid-template-columns: auto $max-sidebar-width;
  grid-template-rows: auto 1fr;

  @include media-queries.laptop {
    padding-inline: spaces.$space-size-6;
  }

  @include media-queries.till-tablet {
    padding-top: spaces.$space-size-4;
    padding-inline: spaces.$space-size-4;
    grid-template-columns: auto $max-mobile-sidebar-width;
    width: 100%;
  }
}

.main {
  padding-bottom: spaces.$space-size-8;
  grid-column: 1 / 3;

  @include media-queries.above-tablet {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    max-width: $max-main-contain-width;
    width: 100%;
    justify-self: center;
  }
}

.name {
  // This is to make the name truncate
  overflow: hidden;
  align-self: center;

  @include media-queries.above-tablet {
    max-width: $max-main-contain-width;
    padding-top: spaces.$space-size-8;
    width: 100%;
    justify-self: center;
  }
}

.mobileSidebar {
  display: none;

  @include media-queries.till-tablet {
    display: block;
    justify-self: end;
  }
}

.sidebar {
  padding-block: spaces.$space-size-20 spaces.$space-size-8;
  grid-column: 1 / 3;

  @include media-queries.above-tablet {
    padding: spaces.$space-size-8 spaces.$space-size-6;
    grid-row: 1/3;
    grid-column: 2/3;
    border-left: 1px solid colors.$color-border;
    grid-row: span 2;
    padding-inline: spaces.$space-size-6;
    min-height: calc(100vh - 50px);
  }
}
