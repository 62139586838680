@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/typography.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.App {
  min-height: calc(100vh - #{app-sizes.$height-header});
}

[data-skip-link] {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 13;
  transform: translate(-50%, -125%);

  @media screen and (prefers-reduced-motion: no-preference) {
    transition: transform 0.2s ease;
  }

  &:focus {
    transform: translate(-50%, 25%);
  }
}

.AppDrawerIsPinned {
  transform: translateX(app-sizes.$width-mobile-navigation);
  max-width: calc(100vw - #{app-sizes.$width-mobile-navigation});
  min-width: calc(100vw - #{app-sizes.$width-mobile-navigation});
}
