$color-gray-00: #fff;
$color-gray-02: #f8f8f8;
$color-gray-05: #f1f1f1;
$color-gray-10: #efefef;
$color-gray-15: #e6e6e6;
$color-gray-20: #dedede;
$color-gray-30: #ccc;
$color-gray-40: #bbb;
$color-gray-50: #9a9a9a;
$color-gray-55: #818181;
$color-gray-60: #666;
$color-gray-70: #5d5d5d;
$color-gray-80: #333;
$color-gray-90: #2a2a2a;
$color-gray-100: #000;

$color-white: $color-gray-00;
$color-black: $color-gray-100;
