@use '~@timelog/ui-library/src/assets/styles/variables/spaces';
@use '~@timelog/ui-library/src/assets/styles/mixins/media-queries';

$max-desktop-context-width: 500px;
$max-tablet-context-width: 400px;
$max-laptop-context-width: 250px;

.widgetContainer {
  display: grid;
  gap: spaces.$space-size-5;
  padding: spaces.$space-size-4 spaces.$space-size-5;
  grid-template-columns: 1fr;
  @include media-queries.tablet {
    grid-template-columns: $max-tablet-context-width 1fr;
  }
  @include media-queries.laptop {
    grid-template-columns: $max-laptop-context-width 1fr;
  }
  @include media-queries.desktop {
    grid-template-columns: $max-desktop-context-width 1fr;
  }
}

.title {
  align-self: start;
  grid-column: 1 / 2;
}

.context {
  align-self: start;
  grid-column: 1 / 2;
  @include media-queries.tablet {
    max-width: $max-tablet-context-width;
  }
  @include media-queries.laptop {
    max-width: $max-laptop-context-width;
  }
  @include media-queries.desktop {
    max-width: $max-desktop-context-width;
  }
}

.video {
  aspect-ratio: 16 / 9;
  width: 100%;
  object-fit: contain;
  grid-column: 2 / 3;
  grid-row: 1 / span 3;
  justify-self: end;
  align-self: center;

  @include media-queries.mobile {
    grid-column: 1;
    grid-row: 2;
  }
}

.button {
  align-self: end;
  grid-column: 1 / 2;
}
