@use '~@timelog/ui-library/src/assets/styles/variables/sizes';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces';
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.IsLegacy {
  overflow: hidden;
}

.LegacyIframe {
  height: calc(100vh - #{app-sizes.$height-header-with-border});
  border: 0;
  // This is to remove the whitespace under the iframe when closing a modal with iframe in it
  display: inline-block;
  vertical-align: bottom;
}

.IsInModal {
  $height-remaining-modal: (2 * (spaces.$space-size-5 + app-sizes.$height-header)) +
    (sizes.$finger-tap-size + spaces.$space-size-6 * 2) + 2;
  // this is a magic number. We don't want the iframe to be higher than the modal
  // 100vh - ((margin around modal * 2) + modal header height + 2px border)
  height: calc(100vh - #{$height-remaining-modal});
}
