@use '~@timelog/ui-library/src/assets/styles/mixins/media-queries.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use 'src/assets/styles/variables/breakpoints.scss' as app-breakpoints;
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.ToggleButton {
  position: fixed;
  z-index: 10;
  right: spaces.$space-size-4;
}

.LayoutSettings {
  padding-top: app-sizes.$height-header-with-border;
  min-height: calc(100vh - #{app-sizes.$height-header});
  display: flex;

  &__nav {
    width: 420px;
    border-right: 1px solid colors.$color-border;
    padding: spaces.$space-size-4;
    max-height: calc(100vh - #{app-sizes.$height-header});
    overflow: auto;

    @include media-queries.desktop {
      padding-left: spaces.$space-size-12;
    }

    @include media-queries.laptop {
      padding-left: spaces.$space-size-6;
    }

    @include media-queries.till-tablet {
      display: none;
      position: fixed;
      top: app-sizes.$height-header;
      right: 0;
      bottom: 0;
      z-index: 10;
      background-color: colors.$color-white;
      border-left: 1px solid colors.$color-border;
      width: app-sizes.$width-mobile-navigation;

      &.IsOpen {
        display: block;
      }
    }
  }

  &__content {
    // Need position relative to position iframe spinner
    position: relative;
    width: 100%;
  }
}
