@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/sizes.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/breakpoints';
@use '~@timelog/ui-library/src/assets/styles/mixins/media-queries';

.ScreenHeaderContainer {
  background-color: var(colors.$css-var-name-theme-color-dark);
  padding: spaces.$space-size-3 spaces.$space-size-5;
  color: colors.$color-white;
  padding-inline: spaces.$space-size-4;
  padding-block: spaces.$space-size-3;

  // this section is to make the screen header stay on the page in case of overflow on x axis
  position: sticky;
  left: 0;
  width: 100%;
  max-width: calc(100vw - var(--scrollBarWidth, 0px));

  @include media-queries.from(breakpoints.$breakpoint-medium) {
    padding-inline: spaces.$space-size-12;
    padding-block: spaces.$space-size-5;
  }

  button {
    svg {
      fill: colors.$color-white;
    }

    &:hover,
    &:focus-visible {
      background-color: colors.$color-gray-05;

      svg {
        fill: colors.$color-gray-60;
      }
    }
  }
}
