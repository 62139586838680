$font-family: 'Noto Sans', Arial, Verdana, sans-serif;

$font-size-subtips: 10px;
$font-size-tips: 12px;
$font-size-body: 14px;
$font-size-sub-heading: 16px;
$font-size-heading: 20px;
$font-size-display: 28px;

$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-normal: 400;

$font-size-table: $font-size-tips;

$line-height-body: 1.5;
