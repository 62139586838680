$space-default: 4px;
$space-size-1: $space-default * 1;
$space-size-2: $space-default * 2;
$space-size-3: $space-default * 3;
$space-size-4: $space-default * 4;
$space-size-5: $space-default * 5;
$space-size-6: $space-default * 6;
$space-size-8: $space-default * 8;
$space-size-12: $space-default * 12;
$space-size-20: $space-default * 20;
