@use '~@timelog/ui-library/src/assets/styles/mixins/media-queries.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/breakpoints.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/typography.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';

.Heading {
  margin-left: spaces.$space-size-1;
}

.BackLink {
  display: none;
  margin-right: spaces.$space-size-4;
  font-weight: typography.$font-weight-medium;

  @include media-queries.from(breakpoints.$breakpoint-xsmall) {
    display: block;
  }
}
