@use '~@timelog/ui-library/src/assets/styles/mixins/media-queries.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/breakpoints.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.TimeTrackerButton__linkText {
  color: colors.$theme-color-pink;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

.TimeTrackerButtonContent {
  position: absolute;
  top: app-sizes.$height-header-with-border;
  right: -(spaces.$space-size-4);
  left: -(spaces.$space-size-4);
  border-top: 1px solid colors.$color-border;
  border-bottom: 1px solid colors.$color-border;
  background-color: colors.$color-white;
  padding: spaces.$space-size-5;

  @include media-queries.from(breakpoints.$breakpoint-xsmall) {
    width: app-sizes.$width-mobile-navigation;
    border: 1px solid colors.$color-border;
    border-top-width: 0;
    right: spaces.$space-size-4;
    left: auto;
  }
}
