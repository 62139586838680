@use 'spaces';

$css-var-name-height-header: --height-header;
$css-var-name-height-header-with-border: --height-header-with-border;
$css-var-name-width-min: --width-min;

$finger-tap-size: 40px;

$height-input: $finger-tap-size;
$height-input-tiny: $finger-tap-size - spaces.$space-size-5;
$height-input-small: $finger-tap-size - spaces.$space-size-2;
$height-input-large: $finger-tap-size + spaces.$space-size-2;
$height-button: $height-input;
$height-button-tiny: $height-input-tiny;
$height-button-small: $height-input-small;
$height-button-large: $height-input-large;
$width-button-icon-only: $height-input;

$height-table-cell: $finger-tap-size + 1; // for border
$height-table-cell-small: $height-input-small + 1; // for border
$height-table-cell-large: $height-input-large + 1; // for border
