@use '~@timelog/ui-library/src/assets/styles/variables/sizes';
@use './variables/sizes.scss' as app-sizes;

/* noto-sans-regular - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Noto Sans'), local('NotoSans'),
    url('../fonts/noto-sans-v7-latin-regular.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Noto Sans Italic'), local('NotoSans-Italic'),
    url('../fonts/noto-sans-v7-latin-italic.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-700 - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Noto Sans Bold'), local('NotoSans-Bold'),
    url('../fonts/noto-sans-v7-latin-700.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-700italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Noto Sans Bold Italic'), local('NotoSans-BoldItalic'),
    url('../fonts/noto-sans-v7-latin-700italic.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('Noto Sans Medium'), local('NotoSans-Medium'),
    url('../fonts/noto-sans-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local('Noto Sans SemiBold'), local('NotoSans-SemiBold'),
    url('../fonts/noto-sans-semi-bold.woff2') format('woff2');
}

:root {
  #{sizes.$css-var-name-height-header}: app-sizes.$height-header;
  #{sizes.$css-var-name-height-header-with-border}: app-sizes.$height-header-with-border;
}

a {
  color: inherit;
  text-decoration: none;
}
