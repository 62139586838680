@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/mixins/resets.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/border-radius.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/typography.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/sizes.scss';

%search-category {
  @include resets.reset-button();
  position: relative;
  display: flex;
  align-items: center;
  gap: spaces.$space-size-2;
  width: 100%;
  height: sizes.$finger-tap-size;
  text-align: left;
  background-color: var(colors.$css-var-name-theme-color-lighter);
  padding-right: spaces.$space-size-8;
  padding-left: spaces.$space-size-2;
  font-weight: typography.$font-weight-bold;
  color: colors.$color-text;
  border-radius: border-radius.$border-radius-default;

  &[aria-expanded='true'] {
    .SearchCategoryArrow {
      transform: rotate(180deg);
    }
  }
}

.SearchCategory {
  @extend %search-category;
}

.SearchCustomersCategory {
  @extend %search-category;
  background-color: colors.$theme-color-green-light;
}

.SearchCategoryArrow {
  display: inline-flex;
  position: absolute;
  right: spaces.$space-size-1;
}

.SearchCategoryResults {
  padding-top: spaces.$space-size-2;
}

.SearchCategoryLink {
  display: flex;
  padding: spaces.$space-size-2;
  border-radius: border-radius.$border-radius-default;
}
