@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces';

.news {
  padding-block: spaces.$space-size-1;
  padding-left: spaces.$space-size-4;
  border-left: spaces.$space-size-1 solid colors.$color-gray-10;
}

.show3News:nth-child(3) ~ * {
  display: none;
}
