@use 'assets/styles/variables/sizes.scss';

html {
  scroll-behavior: smooth;
}

.Spinner {
  display: flex;
  height: calc(100vh - #{sizes.$height-header});
  margin-top: sizes.$height-header;
}
