@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/border-radius.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/sizes.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/typography.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use '~@timelog/ui-library/src/assets/styles/mixins/media-queries.scss';
@use '~@timelog/ui-library/src/assets/styles/mixins/resets.scss';
@use 'src/assets/styles/variables/breakpoints.scss' as app-breakpoints;
@use 'src/assets/styles/variables/colors.scss' as app-colors;
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.ToggleNavigation {
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 0;
  color: app-colors.$color-nav-icon;

  @include media-queries.above-tablet {
    display: none;
  }
}

.CloseLayer {
  @include resets.reset-button();
  position: fixed;
  top: app-sizes.$height-header;
  right: 0;
  bottom: 0;
  width: 100vw;
  background-color: rgba(colors.$color-white, 0.75);
}

.Navigation {
  @include media-queries.till-tablet {
    display: none;
    position: fixed;
    width: app-sizes.$width-mobile-navigation;
    top: app-sizes.$height-header-with-border;
    right: 0;
    bottom: 0;
    background-color: colors.$color-white;
    border-top: 1px solid colors.$color-border;
    border-left: 1px solid colors.$color-border;
  }
}

.MobileNavIsOpen {
  @include media-queries.till-tablet {
    display: block;
  }
}

.Wrapper {
  @include media-queries.till-tablet {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

// Lists in navigation
.ParentList,
.ChildList,
.GrandChildList {
  @include resets.reset-list();
}

.ParentList {
  @include media-queries.above-tablet {
    display: flex;
  }
}

.ChildList,
.GrandChildList {
  display: none;
  min-width: 100%;

  @include media-queries.till-tablet {
    padding-top: spaces.$space-size-3;
    padding-bottom: spaces.$space-size-3;
  }

  @include media-queries.above-tablet {
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background-color: colors.$color-white;
    border: 1px solid colors.$color-border;
    border-radius: 0 0 border-radius.$border-radius-small border-radius.$border-radius-small;
    padding-top: spaces.$space-size-2;
    padding-bottom: spaces.$space-size-2;
    box-shadow: 3px 3px 10px rgba(colors.$color-black, 0.01);
    transition: opacity 0.2s ease;
  }
}

.ChildList {
  @include media-queries.above-tablet {
    top: app-sizes.$height-header;

    // This is because overflow-y: auto will add a scrollbar
    // on the horizontal axis
    &:not(.ChildListWithXOverflow) {
      max-height: calc(100vh - #{app-sizes.$height-header} - #{spaces.$space-size-4});
      overflow-y: auto;
    }
  }
}

.ChildListMaxWidth {
  @include media-queries.above-tablet {
    width: 300px;
  }
}

.GrandChildList {
  top: calc(-#{spaces.$space-size-2} - 1px); // Compensating for border-width
  left: 100%;
}

// List items in navigation
.ParentItem {
  position: relative;

  @include media-queries.till-tablet {
    border-bottom: 1px solid colors.$color-border;
  }

  @include media-queries.above-tablet {
    &:focus-within,
    &:hover {
      color: colors.$color-gray-90;
      background-color: colors.$color-gray-05;

      .ChildList {
        opacity: 1;
        pointer-events: auto;
      }

      .ParentLinkTextIcon svg {
        transform: rotate(-180deg);
      }
    }
  }
}

@include media-queries.above-tablet {
  .ParentItemActive,
  .ChildItemActive {
    background-color: colors.$color-gray-05;
  }
}

.ParentItemSpinner {
  display: flex;
  align-items: center;
  padding-right: spaces.$space-size-5;
  padding-left: spaces.$space-size-5;
}

.ChildItem,
.GrandChildItem {
  @include media-queries.above-tablet {
    &__hasBorderTop {
      border-top: 1px solid colors.$color-border;
      margin-top: spaces.$space-size-2;
      padding-top: spaces.$space-size-2;
    }

    &:focus-within,
    &:hover {
      > .ChildLink,
      > .GrandChildLink {
        background-color: colors.$color-gray-05;
        text-decoration: none;
      }
    }
  }
}

.ChildList__hasGrandChildList {
  @include media-queries.till-tablet {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 1px solid colors.$color-border;
  }

  > .ChildItem {
    position: relative;

    @include media-queries.till-tablet {
      &:not(:last-child) {
        border-bottom: 1px solid colors.$color-border;
      }

      > .TriggerSubNavButton {
        padding-left: spaces.$space-size-8;
      }

      .GrandChildLink {
        padding-left: spaces.$space-size-8 + spaces.$space-size-1;
      }
    }

    @include media-queries.above-tablet {
      > .ChildLink {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
      }

      > .ChildLink::after {
        content: '';
        display: block;
        width: 5px;
        height: 7px;
        background-color: app-colors.$color-nav-icon;
        clip-path: polygon(0 0, 100% 50%, 0 100%);
        margin-left: spaces.$space-size-3;
      }
    }

    &:focus-within,
    &:hover {
      .GrandChildList {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

// Links in navigation
.ParentLink,
.ChildLink,
.GrandChildLink {
  @include resets.reset-button(); // In case it is a button
  display: block;
  width: 100%; // In case it is a button
  text-align: left; // In case it is a button
  white-space: nowrap;
  padding-right: spaces.$space-size-3;
  padding-left: spaces.$space-size-3;

  @include media-queries.till-tablet {
    padding-top: spaces.$space-size-3;
    padding-bottom: spaces.$space-size-3;
  }
}

.ParentLink {
  display: flex;
  font-weight: typography.$font-weight-medium;

  @include media-queries.above-tablet {
    height: app-sizes.$height-header;
    line-height: app-sizes.$height-header;
  }

  &[aria-current='page'] {
    background-color: colors.$color-gray-05;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    color: colors.$color-gray-90;
  }

  &__hasIcon {
    display: flex;
    align-items: center;
    color: app-colors.$color-nav-icon;

    @include media-queries.above-tablet {
      justify-content: center;
      width: app-sizes.$height-header;
      padding-right: 0;
      padding-left: 0;

      &:hover,
      &:focus-visible {
        background-color: colors.$color-gray-05;
      }
    }

    .ParentLinkText {
      margin-left: spaces.$space-size-1;

      @include media-queries.above-tablet {
        display: none;
      }
    }
  }
}

.ParentLinkHasSubNav,
.ChildLinkHasSubNav {
  @include media-queries.till-tablet {
    display: none;
  }
}

.ChildLink,
.GrandChildLink {
  padding-top: spaces.$space-size-3;
  padding-bottom: spaces.$space-size-3;
  color: colors.$color-text;

  @include media-queries.till-tablet {
    padding-top: spaces.$space-size-2;
    padding-bottom: spaces.$space-size-2;
  }

  &__hasIcon {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: spaces.$space-size-1;
    }
  }

  // States
  &[aria-current='page'] {
    font-weight: typography.$font-weight-bold;
  }
}

.ChildLink {
  @include media-queries.till-tablet {
    padding-left: spaces.$space-size-8;
  }
}

.ParentLinkTextIcon {
  position: relative;
  top: 2px;
  line-height: 1;

  @include media-queries.till-tablet {
    display: none;
  }

  svg {
    @media screen and (prefers-reduced-motion: no-preference) {
      transition: transform 0.2s ease;
    }
  }
}

// Main nav buttons
.TriggerSubNavButton {
  @include media-queries.till-tablet {
    @include resets.reset-button();
    display: block;
    width: 100%;
    position: relative;
    text-align: left;
    font-size: typography.$font-size-body;
    font-family: typography.$font-family;
    font-weight: typography.$font-weight-medium;
    line-height: typography.$line-height-body;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: colors.$color-text;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: spaces.$space-size-4;
    }

    &[aria-expanded='true'] {
      svg {
        transform: translateY(-50%) rotate(180deg);
      }

      + .ChildList,
      + .GrandChildList {
        display: block;
      }
    }
  }

  @include media-queries.above-tablet {
    display: none !important;
  }
}

.ParentLink,
.TriggerSubNavButton {
  @include media-queries.till-tablet {
    padding: spaces.$space-size-4;
  }
}

.TriggerSubNavButton {
  @include media-queries.till-tablet {
    padding-right: spaces.$space-size-12;
  }
}

.TriggerSubNavButtonIsActive {
  background-color: colors.$color-gray-05;
}
