@use '~@timelog/ui-library/src/assets/styles/mixins/resets.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/border-radius.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/sizes.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/typography.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.SearchCategories {
  // Two time padding + height of input + 1px border
  $top-position: spaces.$space-size-8 + sizes.$height-input + 1;

  position: absolute;
  top: $top-position;
  right: 0;
  z-index: 1;
  padding: spaces.$space-size-4;
  background-color: colors.$color-white;
  max-width: 200px;
  border: 1px solid colors.$color-border;
  border-top-width: 0;
  max-height: calc(
    100vh - #{app-sizes.$height-header} - #{spaces.$space-size-5} - #{$top-position}
  );
  box-shadow: 3px 3px 10px rgba(colors.$color-black, 0.15);
  overflow: auto;

  [aria-selected='true'] {
    background-color: colors.$color-gray-05;
    box-shadow: 0 0 0 1px colors.$color-gray-40;
  }
}

.SearchCategoriesList {
  @include resets.reset-list();

  > li:not(:first-child) {
    margin-top: spaces.$space-size-4;
  }
}

.Checkmark {
  display: none;
  color: colors.$theme-color-pink;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.SearchFilterCategoryItem {
  @include resets.reset-button();
  position: relative;
  width: 100%;
  text-align: left;
  padding-right: spaces.$space-size-8;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: spaces.$space-size-5;
    height: spaces.$space-size-5;
    border: 1px solid colors.$color-border;
    border-radius: border-radius.$border-radius-small;
  }

  &[aria-pressed='true'] {
    .Checkmark {
      display: flex;
    }
  }
}

.SearchFilterCategoryItemInner {
  display: flex;
  align-items: center;
  padding: spaces.$space-size-1;
  font-size: typography.$font-size-body;
  font-weight: typography.$font-weight-normal;
  background-color: var(colors.$css-var-name-theme-color-lighter);

  svg {
    margin-right: spaces.$space-size-1;
    min-width: spaces.$space-size-4;
  }
}

.SearchFilterhCustomersCategoryItem {
  .SearchFilterCategoryItemInner {
    background-color: colors.$theme-color-green-light;
  }
}
