@use 'src/assets/styles/variables/breakpoints.scss' as app-breakpoints;
@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/typography.scss';
@use '~@timelog/ui-library/src/assets/styles/mixins/media-queries.scss';

.BookmarkTitle {
  display: block;
  white-space: nowrap;
  margin-bottom: spaces.$space-size-2;
  padding: spaces.$space-size-3 spaces.$space-size-3 spaces.$space-size-5;
  border-bottom: 1px solid colors.$color-border;

  @include media-queries.till-tablet {
    display: none;
  }
}

.BookmarkEmptyState {
  padding-inline: spaces.$space-size-3;
  padding-block-end: spaces.$space-size-3;

  @include media-queries.till-tablet {
    padding-top: spaces.$space-size-3;
    padding-bottom: spaces.$space-size-3;
  }
}
