@use '../variables/breakpoints';

@mixin from($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin until($breakpoint) {
  @media screen and (max-width: $breakpoint - 1) {
    @content;
  }
}

// mobile first concept, always small to big
@mixin mobile {
  @media screen and (max-width: breakpoints.$breakpoint-mobile - 1) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: breakpoints.$breakpoint-mobile) and (max-width: breakpoints.$breakpoint-tablet - 1) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: breakpoints.$breakpoint-tablet) and (max-width: breakpoints.$breakpoint-laptop - 1) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: breakpoints.$breakpoint-laptop) {
    @content;
  }
}

// till
@mixin till-tablet {
  @media screen and (max-width: breakpoints.$breakpoint-tablet - 1) {
    @content;
  }
}

@mixin till-laptop {
  @media screen and (max-width: breakpoints.$breakpoint-laptop - 1) {
    @content;
  }
}

// above
@mixin above-tablet {
  @media screen and (min-width: breakpoints.$breakpoint-tablet - 1) {
    @content;
  }
}

@mixin above-laptop {
  @media screen and (min-width: breakpoints.$breakpoint-laptop - 1) {
    @content;
  }
}
