@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';

.Search {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: colors.$color-white;
  border: 1px solid colors.$color-border;
  border-top-width: 0;
  padding: spaces.$space-size-4;
}

.SearchInput {
  width: 100%;
  margin-right: -1px;
}

.SearchCategoryButton {
  &:not(:focus-visible) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &[aria-expanded='true'] {
    background-color: colors.$color-gray-05;
  }

  &:focus-visible {
    position: relative;
    z-index: 1;
  }
}

.SearchCategoryButtonHasActiveFilter {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 9px;
    right: 6px;
    background-color: colors.$theme-color-pink;
    border-radius: 50%;
  }
}
