@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.ProcessDrawer {
  position: fixed;
  top: app-sizes.$height-header;
  left: 0;
  z-index: 8;
  width: app-sizes.$width-mobile-navigation;
  height: calc(100vh - #{app-sizes.$height-header});
  background-color: colors.$color-white;
  border-right: 1px solid colors.$color-border;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateX(-(app-sizes.$width-mobile-navigation));
}

.ProcessDrawerInner {
  padding: spaces.$space-size-4 spaces.$space-size-5;
}

// Actions on drawer
.ProcessDrawerIsActive {
  transform: translateX(0);
}
